import React, {Suspense, useContext} from 'react';
import { Switch, Redirect } from 'react-router-dom';
import LoadingCircle from "../components/LoadingCircle/LoadingCircle";

import Route from './Route';
import { EspecificRoutes } from './EspecificRoutes';
import { LocationContext } from '../components/Location/Location';

const Home_newPage = React.lazy(() => import('../pages/Home_new/Home'));
const Home_new = () => <Suspense fallback={<LoadingCircle/>}>
  <Home_newPage/>
</Suspense>

const BlogPage = React.lazy(() => import('../pages/Blog/Blog'));
const Blog = () => <Suspense fallback={<LoadingCircle/>}>
  <BlogPage/>
</Suspense>

const BlogDetailPage = React.lazy(() => import('../pages/Blog/BlogDetail/BlogDetail'));
const BlogDetail = () => <Suspense fallback={<LoadingCircle/>}>
  <BlogDetailPage/>
</Suspense>

const HealthPage = React.lazy(() => import('../pages/Health/Health'));
const Health = () => <Suspense><HealthPage /></Suspense>

const commomRoutes = [
  <Route exact={true} path="/" key="/" component={Home_new} />,
  <Route key={1} path="/home" component={Home_new} />,
  <Route key={2} path="/blog/:code_seo" component={BlogDetail} />,
  <Route key={3} path="/blog" component={Blog} />,
  <Route path="/health" component={Health}></Route>
];

const Router = () => {
  const { country } = useContext(LocationContext);
  const route = commomRoutes.concat(EspecificRoutes[country]);

  return (
    <Switch>
      {route}
      <Redirect key={4} to="/home" />
    </Switch>
  );
};

export default Router;
